import type { SeoModel } from '$lib/models/seo.models';

export enum PageViewType {
    LIST,
    MAP,
    OTHER
}

export interface PageDataModel extends SeoModel{
    hover?: string;
    imagePath?: string;
    title?: string;
}

export interface PageMetaTag {
    content: string | number;
    property: string;
}
